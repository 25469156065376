<template>
  <div class="content">
    <h1>Picture Upload</h1>
    <div v-if="screen" class="flex flex-col gap-4">
      <img :src="screen.file.link" style="max-height: 80vh;" class="object-scale-down">
      <div class="flex justify-end w-100">
        {{ screen.file_date }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PictureUpload',
  components: {},
  data() {
    return {
      timer: null,
      screen: null,
    };
  },
  methods: {
    fetchScreen() {
      this.$http
        .get('/screen')
        .then((res) => {
          this.screen = res.body;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch screen: ${err.response.text}`);
        });
    },
  },
  created() {
    this.fetchScreen();
    this.timer = setInterval(() => {
      this.fetchScreen();
    }, 15 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
</style>
